var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Login"},[_c('div',{staticClass:"login-box"},[_c('div',{staticClass:"signin_box"},[_c('h2',{staticClass:"main_title"},[_vm._v(" 林地資訊管理 ")]),_c('b-form-group',{attrs:{"label":"帳號"}},[_c('b-form-input',{attrs:{"placeholder":"帳號"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.loginData.Type = 'login';
            _vm.userLogin();}},model:{value:(_vm.loginData.Id),callback:function ($$v) {_vm.$set(_vm.loginData, "Id", $$v)},expression:"loginData.Id"}})],1),_c('b-form-group',{attrs:{"label":"密碼"}},[_c('b-form-input',{attrs:{"placeholder":"請輸入密碼","type":"password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.loginData.Type = 'login';
            _vm.userLogin();}},model:{value:(_vm.loginData.DecryptionPass),callback:function ($$v) {_vm.$set(_vm.loginData, "DecryptionPass", $$v)},expression:"loginData.DecryptionPass"}})],1),_c('b-form-group',{attrs:{"label":"驗證碼"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-input',{attrs:{"placeholder":"請輸入驗證碼"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.loginData.Type = 'login';
                _vm.userLogin();}},model:{value:(_vm.loginData.CodePass),callback:function ($$v) {_vm.$set(_vm.loginData, "CodePass", $$v)},expression:"loginData.CodePass"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.imageCode,"alt":"captchaImage"}})])],1)],1),_c('p',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.ForgetPasswordModal",modifiers:{"ForgetPasswordModal":true}}]},[_vm._v("忘記密碼")])],1),_c('button',{staticClass:"btn btn-info btn-block",on:{"click":function($event){_vm.loginData.Type = 'login';
          _vm.userLogin();}}},[_vm._v(" 登入 ")]),_c('div',{staticClass:"social-media-icon"},[_c('b-row',{staticClass:"bv-example-row"},[_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-button',{staticClass:"btn btn-success btn-block",staticStyle:{"width":"100%","background-color":"#06c755","height":"40px","border":"none"},on:{"click":function($event){_vm.loginData.Type = 'Line';
                _vm.userLogin();}}},[_c('b-img',{staticStyle:{"position":"absolute","left":"25px","top":"5px","width":"30px","height":"30px"},attrs:{"src":require('@/assets/imgs/btn_login.png')}}),_c('span',{staticClass:"line-login-title"},[_vm._v("使用Line帳戶登入")])],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-button',{staticClass:"btn btn-light btn-block",on:{"click":function($event){_vm.loginData.Type = 'Google';
                _vm.userLogin();}}},[_c('b-img',{staticStyle:{"position":"absolute","left":"25px","top":"5px","width":"25px","height":"25px"},attrs:{"src":require('@/assets/imgs/google_logo.png')}}),_c('span',[_vm._v("使用Google帳戶登入")])],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"sm":"12","md":"6","lg":"6"}},[_c('GoogleLogin',{attrs:{"id":"GoogleLoginButton","params":_vm.googleParams,"renderParams":_vm.googleLoginCss,"onSuccess":_vm.googleLoginSuccess}})],1)],1)],1)],1)]),_c('div',{staticClass:"logo-box"},[_c('div',{staticClass:"main-logo"},[_c('b-img',{attrs:{"src":require('@/assets/imgs/FANCA_MOA_(Taiwan)_logo.svg.png'),"fluid":"","alt":"林務局logo"}}),_c('h2',[_vm._v("林業及自然保育署臺東分署")])],1)]),_c('AlertModal',{attrs:{"alertModalObj":_vm.alertModalObj}}),_c('ForgetPasswordModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }